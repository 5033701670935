import config from './config';
import * as iframe from './iframe';
import * as utils from './utils';
import log from './log';
import { wallIsActivated } from './init';

/**
 * Initialize ToggleSelfService
 */
export function initToggleSelfServiceMenu() {
    let styleConfig = (utils.deviceIsMobile() === true && config.iframeConfig.mobileConfig.matchDesktop === false) ? config.selfServiceMenuButton.mobileStyle : config.selfServiceMenuButton.desktopStyle;

    let selfServiceMenu = document.createElement('div');
    selfServiceMenu.innerHTML = `
        <svg style="border-radius: 3px" width="${styleConfig.toggleWidth}" height="${styleConfig.toggleHeight}">
            <g>
                <rect width="${styleConfig.toggleWidth}" height="${styleConfig.toggleHeight}" style="fill:${styleConfig.toggleBackground};stroke-width:${styleConfig.toggleBorderWidth};stroke:${styleConfig.toggleBorderColor}" />
                <text x="${styleConfig.toggleTextXPosition}" y="${styleConfig.toggleTextYPosition}" font-family="${styleConfig.toggleTextFontFamily}" font-size="${styleConfig.toggleTextFontSize}" fill="${styleConfig.toggleTextFontColor}">${styleConfig.toggleTextValue}</text>
                Sorry, your browser does not support inline SVG.
            </g>
        </svg>`

    selfServiceMenu.id = 'self-service-menu';
    selfServiceMenu.style.cssText = 'position: fixed; cursor: pointer; boxShadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);';

    // If z-index is not set in config, set it to max z-index - 1 to be bellow consent wall
    selfServiceMenu.style.zIndex = config.selfServiceMenuButton.zIndex || 2147483646;

    let placeholder;
    switch (config.selfServiceMenuButton.position) {
        case 'topRight':
            selfServiceMenu.style.top = '20px';
            selfServiceMenu.style.right = '20px';
            break;
        case 'bottomRight':
            selfServiceMenu.style.bottom = '20px';
            selfServiceMenu.style.right = '20px';
            break;
        case 'topLeft':
            selfServiceMenu.style.top = '20px';
            selfServiceMenu.style.left = '20px';
            break;
        case 'bottomLeft':
            selfServiceMenu.style.bottom = '20px';
            selfServiceMenu.style.left = '20px';
            break;
        default:
            placeholder = document.querySelector(config.selfServiceMenuButton.position);
            if (!placeholder) {
                selfServiceMenu.style.bottom = '28px';
                selfServiceMenu.style.right = '40px';
            }
            break;
    }

    selfServiceMenu.onclick = function () {
        iframe.showIframe();
        hideToggleSelfService();
    };

    selfServiceMenu.style.display = 'none';

    if (placeholder) {
        selfServiceMenu.style.position = 'initial';
        placeholder.appendChild(selfServiceMenu);
    } else {
        document.body.appendChild(selfServiceMenu);
    }
}

export function hideToggleSelfService() {
    if (config.selfServiceMenuButton.displaySelfServiceMenu) {
        let selfServiceMenu = document.getElementById('self-service-menu');
        if (selfServiceMenu) {
            selfServiceMenu.style.display = 'none';
        }
    }
}

export function showToggleSelfService() {
    if (config.selfServiceMenuButton.displaySelfServiceMenu) {
      toogleSelfServiceButton();
    }
}

export function toogleSelfServiceButton() {
  let selfServiceMenu = document.getElementById('self-service-menu');
  if (selfServiceMenu) {
    selfServiceMenu.style.display = 'block';
  }
}

export function shouldToggleSelfService() {
    if (utils.isUserLoggedIn()) {
        showToggleSelfService();
    }
}

export function triggerSelfService() {
    if (!wallIsActivated) {
        return;
    }
    if (!utils.isUserLoggedIn()) {
        log.debug("Self service won't be toggled, user is not logged in!");
        return;
    }
    toogleSelfServiceButton();
}
