import config from './config';
import log from './log';

import * as iframeCommunication from './iframe-communication';
import * as iframe from './iframe';

import { initToggleSelfServiceMenu } from './toggleSelfService';
import * as utils from './utils'

let wallIsActivated = false;
export { wallIsActivated };

export function init(configObject) {

    if (configObject) {

        // Check if AWS Amplify config object is provided.
        if (!configObject.iframeConfig.awsConfig) {
            log.debug("Fields aws_user_pools_web_client_id and aws_user_pools_id are required.");
            return;
        }
        // Check if AWS Amplify config fields are provided - user pool id, region and client id.
        if (!configObject.iframeConfig.awsConfig.aws_user_pools_web_client_id || !configObject.iframeConfig.awsConfig.aws_user_pools_id) {
            log.debug("Fields aws_user_pools_web_client_id and aws_user_pools_id are required.");
            return;
        }
    }
    config.update(configObject);

    // if browser is not supported, stop the library execution
    if (!utils.browserIsSupported()) {
        log.debug('Browser blocked by configuration.');
        return;
    }

    log.debug("Config object: ", configObject);
    showIframe();
    initToggleSelfServiceMenu();
}

function showIframe() {
    if (config.showIframe && !wallIsActivated) {
        iframeCommunication.attachEventListeners();
        iframe.triggerIframeCreation();
        wallIsActivated = true;
    }
}
