import config from './config';
import log from './log';
import * as iframe from './iframe';
import * as utils from './utils';
import { shouldToggleSelfService } from './toggleSelfService';
import { hideToggleSelfService } from "./toggleSelfService";
import { activateFeatureToggle } from "./feature-toogle";

/**
 * Add Event Listener so we the reg-wall application can communicate with library.
 */
export function attachEventListeners() {
    log.debug('EventListener attached!');
    window.addEventListener('message', receiveMessage);
}

/**
 * Send data through postMessage to the reg-wall application.
 *
 * @param {String} command - string property which will tell application what to do with data.
 * @param {Object} data - object that will be passed to the application.
 */
export function sendMessage(command, data) {

    log.debug('Sending event command is: ' + command);
    let iframeWindow = document.getElementById(config.registrationWallId).contentWindow;
    //later change '*' to a real application domain.
    iframeWindow.postMessage({ 'command': command, 'data': data }, '*');
}

/**
 * Send config file to the reg-wall app.
 */
function sendConfiguration() {
    log.debug('Sending config file to the reg-wall.');
    sendMessage('configuration', config.iframeConfig);
}

/**
 * This function will receive Event and further call function that needs to be called.
 */
function receiveMessage(event) {

    //read command that was sent with Event.
    let eventCommand = event && event.data ? event.data.command : '';
    log.debug('Received event command is: ' + eventCommand);

    //Reg-wall application asks for configuration
    if (eventCommand === 'get-configuration') {
        sendConfiguration();
    } else if (eventCommand === 'trigger-regwall') {
        iframe.setIframeDisplay();
    } else if (eventCommand === 'hide-regwall') {
        iframe.hideIframe();
        shouldToggleSelfService();
    } else if (eventCommand === 'show-self-service-menu') {
        iframe.setIframeDisplay();
    } else if (eventCommand === 'user-data') {
        utils.setCognitoUserInfo(event.data.data);
    } else if (eventCommand === 'user-is-logged-in') {
        utils.setUserIsLoggedIn(true);
        shouldToggleSelfService();
        utils.getUserInfo();
        utils.sendIdentifierToAts(event.data.data);
    } else if (eventCommand === 'hide-toggle-menu-button') {
        hideToggleSelfService();
    } else if (eventCommand === 'user-is-not-logged-in') {
        utils.logOutUser();
    } else if (eventCommand === 'user-signed-up') {
        activateFeatureToggle(true);
    }
}