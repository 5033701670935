import { init } from './lib/init';
import { triggerSelfService } from './lib/toggleSelfService';
import { getUserIdentifier } from './lib/utils';
import {evaluateFeatureToggle, isRegistrationWallEnabled} from "./lib/feature-toogle";

export function start(config) {
    if (evaluateFeatureToggle(config.activeThresholdPercentage)) {
        init(config);
    }
}

export function toggleSelfService() {
    if (isRegistrationWallEnabled()) {
        triggerSelfService();
    }
}

export function getUserStatus(callback) {
    if (isRegistrationWallEnabled()) {
        let userInfo = getUserIdentifier();
        if (callback) {
            callback(userInfo);
        } else {
            return userInfo;
        }
    }
}
