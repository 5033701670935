import config from '../config/config';

const defaultConfig = process.env.CONFIG || config.generate();

/**
 * Check if configuration object is valid and if so update the defaultConfig values.
 */
function updateConfiguration(config, update) {

    let updatedConfig = { ...config };

   return updateNestedObjects(updatedConfig, update);
}


export function updateNestedObjects(updatedConfig, update) {

    for (let i in update) {
        if (typeof update[i] === 'object') {
            updatedConfig[i] = updateNestedObjects(updatedConfig[i] ? updatedConfig[i] : {}, update[i]);
        } else {
            updatedConfig[i] = update[i];
        }
    }
    return updatedConfig;
}

class Config {
    constructor() {
        this.update(defaultConfig);
    }

    update = (configObject) => {
        if (configObject) {
            const updatedConfig = updateConfiguration(this, configObject);
            Object.assign(this, updatedConfig);
        }
    };
}

export default new Config();
