import log from './log';
import { sendMessage } from './iframe-communication';
import config from './config';

const userAgent = require('ua-parser-js');
const userAgentParser = new userAgent();

export { userAgentParser };

const userInformation = {
    isLoggedIn: false
};

export { userInformation };

export function getIframeHeight(headerHeight, bodyHeight) {
    log.debug("Header height is " + headerHeight);
    log.debug("Body height is " + bodyHeight);
    return (40 + +headerHeight.replace('px', '') + +bodyHeight.replace('px', '')) + 'px';
}

/**
 * Check if device is telephone.
 */
export function deviceIsMobile() {
    return userAgentParser.getDevice().type === 'mobile';
}

export function setUserIsLoggedIn(userIsLoggedIn) {
    userInformation.isLoggedIn = userIsLoggedIn;
}

export function isUserLoggedIn() {
    return userInformation.isLoggedIn;
}

export function setCognitoUserInfo(userInfo) {
    if (userInfo) {
        userInformation.cognitoUserInfo = userInfo.Value;
    }
}

export function getUserIdentifier() {
    if (userInformation.cognitoUserInfo) {
        return {
            'userIsLoggedIn': true,
            'userIdentifier': userInformation.cognitoUserInfo
        }
    }
    return {
        'userIsLoggedIn': false
    }
}

export function getUserInfo() {
    if (isUserLoggedIn()) {
        if (!userInformation.cognitoUserInfo) {
            sendMessage('get-user-data');
        }
    }
}

export function logOutUser() {
    userInformation.isLoggedIn = false;
    userInformation.cognitoUserInfo = undefined;
}

/**
 * Validate provided sub-domain.
 * @param {String} sub domain
 * @returns {*}
 */
export function domainValidation(subdomain) {
    const regExpCondition = /^[a-zA-Z0-9][a-zA  -Z0-9.-]+[a-zA-Z0-9]$/;
    if (regExpCondition.test(subdomain)) {
        return `${subdomain}.`;
    } else {
        log.debug('invalid sub domain provided');
        return '';
    }
}

export function sendIdentifierToAts(userInfo) {
    if (config.publisherId && config.publisherId !== -1) {
        if (window.ats) {
            if (userInfo && userInfo.username) {
                window.ats.start(
                    {
                        'placementID': config.publisherId,
                        'email': userInfo.username.includes("@") ? userInfo.username : undefined,
                        'phoneNumber': !userInfo.username.includes("@") ? userInfo.username : undefined
                    }
                );
            }
        }
    }
}

/*
* Browser support
*/
export function browserIsFirefox() {
    return typeof InstallTrigger !== 'undefined';
}

export function browserIsIE() {
    return /*@cc_on!@*/false || !!document.documentMode;
}

export function browserIsEdge() {
    return userAgentParser.getBrowser().name === 'Edge';
}

export function browserIsChrome() {
    return userAgentParser.getBrowser().name === 'Chrome';
}

export function browserIsSafari() {
    return navigator.vendor.includes("Apple");
}

export function browserIsSupported() {

    //if publisher didn't pass any white-list for browsers
    //it means that all browsers are supported
    if (config.browsers === undefined) {
        return true;
    }

    //if there is certain browser in the configuration and it is the current browser being used
    //by the user, this function should return true
    let browsers = Object.values(config.browsers);
    if ((browsers.indexOf("chrome") > -1 && browserIsChrome()) || (browsers.indexOf("edge") > -1 && browserIsEdge())
        || (browsers.indexOf("firefox") > -1 && browserIsFirefox()) || (browsers.indexOf("safari") > -1 && browserIsSafari())) {
        return true;
    }
    //not browser matching configuration was found
    return false;
}
