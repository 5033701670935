// Has been refactored due to testing purposes

export const FEATURE_TOGGLE_FLAG = "rw_feature_toggle_flag";

export function shouldActivate(thresholdPercentage) {
  return Math.random() * 100 < thresholdPercentage;
}

export function getFeatureFlag() {
  return localStorage.getItem(FEATURE_TOGGLE_FLAG);
}

export function setFeatureFlag(featureFlagString) {
  localStorage.setItem(FEATURE_TOGGLE_FLAG, featureFlagString);
}

export function removeFeatureFlag() {
  localStorage.removeItem(FEATURE_TOGGLE_FLAG);
}