// Reference doc: https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie

/**
 * Set a cookie
 * @param {string} name, cookie name
 * @param {string} value, cookie value
 * @param {number} age, number of seconds before the cookie expires
 * @param {string} path attribute of the cookie
 */
export function setCookie(name, value, age = 365 * 24 * 60 * 60, path = '/') {
  let date = new Date();
  date.setTime(date.getTime() + (age * 1000));
  let expires = `;expires=${date.toUTCString()}`;
  let max_age = `;max-age=${age}`;
  let cpath = `;path=${path}`;

  document.cookie = `${name}=${encodeURIComponent(value)}${max_age}${expires}${cpath}`;
}

/**
 * Get a cookie
 * @param {string} cname, cookie name
 * @return {string} String, cookie value
 */
export function getCookie(cname) {
  let name = cname + "=";
  let attr = document.cookie.split(';');

  // Loop through the cookies and return the cookie value if it find the cookie name
  for (let i = 0; i < attr.length; i++) {
    let c = attr[i].trim();
    // If the name is the cookie string at position 0, we found the cookie and return the cookie value
    if (c.indexOf(name) === 0)
      return decodeURIComponent(c.substring(name.length, c.length));
  }

  // If we get to this point, that means the cookie wasn't found and we return an empty string.
  return "";
}

/**
 * Delete a cookie
 * @param {String} name, cookie name
 */
export function deleteCookie(name ) {
  let date = new Date();
  date.setTime(date.getTime() - (1000 * 60 * 60 * 24)); // Set the time to the past

  let expires = `expires=${date.toUTCString()}`; // Compose the expiration date
  document.cookie = `${name}=;path=/;${expires}`; // Set empty cookie name and the expiration date
}
